<script setup lang="ts">
import { computed, ref, onMounted, onUnmounted, watch } from "vue";
import { usePatientStore } from "~/stores/patientStore";
import Spinner from "~/components/Spinner.vue";
import { useRouter } from "vue-router";

const patientStore = usePatientStore();
const isLoading = ref(true);

onMounted(async () => {
  await patientStore.loadPatients();
  isLoading.value = false;
});

const router = useRouter();
const paginatedPatients = computed(() => patientStore.paginatedPatients);
const totalPages = computed(() => patientStore.totalPages);
const searchQuery = ref("");
const selectedFilter = computed(() => patientStore.selectedFilter);
const currentPage = computed(() => patientStore.currentPage);
const itemsPerPage = computed(() => patientStore.itemsPerPage);
const filters = ref([
  "All",
  "Today",
  "New Patient",
  "Diagnostic",
  "Treatment",
  "Post-Treatment",
]);

watch(searchQuery, (newQuery) => {
  patientStore.setSearchQuery(newQuery);
});

const showProgressColumn = computed(
  () => selectedFilter.value === "All" || selectedFilter.value === "Today"
);

const showAppointmentColumn = computed(() => selectedFilter.value === "Today");

const setPage = (page: number) => {
  if (page >= 1 && page <= totalPages.value) {
    patientStore.setPage(page);
  }
};

const setItemsPerPage = (event: Event) => {
  const value = parseInt((event.target as HTMLSelectElement).value, 10);
  const totalItems = patientStore.filteredPatients.length;
  const newTotalPages = Math.ceil(totalItems / value);

  if (currentPage.value > newTotalPages) {
    // If the current page would be invalid with the new items per page,
    // reset to the first page
    patientStore.setPage(1);
  }

  patientStore.setItemsPerPage(value);
};

const setFilter = (filter: string) => {
  patientStore.setFilter(filter);
  dropdownOpen.value = false;
};

const currentPageStart = computed(
  () => (currentPage.value - 1) * itemsPerPage.value + 1
);

const currentPageEnd = computed(() =>
  Math.min(
    currentPage.value * itemsPerPage.value,
    patientStore.filteredPatients.length
  )
);

const totalPatients = computed(() => patientStore.filteredPatients.length);

const dropdownOpen = ref(false);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};

const getPatientRowClass = computed(() => {
  if (showProgressColumn.value && showAppointmentColumn.value) {
    return "grid grid-cols-4";
  } else if (showProgressColumn.value || showAppointmentColumn.value) {
    return "grid grid-cols-3";
  } else {
    return "grid grid-cols-2";
  }
});

const sortIconClass = computed(() =>
  patientStore.sortDirection === "asc" ? "fa-sort-up" : "fa-sort-down"
);

const toggleSortDirection = () => {
  const newDirection = patientStore.sortDirection === "asc" ? "desc" : "asc";
  patientStore.sortPatientsByProgress(newDirection);
};
</script>

<template>
  <!-- Full Block Wrapper -->
  <div
    class="w-full bg-white max-w-screen-xl justify-center mx-auto mt-5 mb-5 container sm:rounded-xl shadow-sm overflow-hidden"
  >
    <!-- Header with Tabs -->
    <div class="flex flex-wrap justify-between items-center px-3 sm:py-2">
      <!-- Tabs for Desktop -->
      <ul
        class="hidden md:flex flex-wrap text-xs lg:text-sm font-medium text-center text-gray-500 dark:text-gray-400"
      >
        <li
          v-for="filter in filters"
          :key="filter"
          :class="{
            'mr-2 pb-1 relative': true,
            'text-primary-600 text-sm font-work font-semibold':
              selectedFilter === filter,
            'hover:text-primary-600 font-work dark:hover:text-white':
              selectedFilter !== filter,
          }"
          @click.prevent="setFilter(filter)"
          style="cursor: pointer"
        >
          <a class="inline-block px-4 pt-5">{{ filter }}</a>
          <span
            v-if="selectedFilter === filter"
            class="absolute left-1/2 transform -translate-x-1/2 bottom-0 h-0.5 bg-primary-600"
            style="width: 60%; margin-bottom: -9px"
          ></span>
        </li>
      </ul>

      <!--Search Input-->
      <div class="relative mt-3 w-full md:w-1/5 lg:w-1/3 sm:mt-0">
        <input
          type="text"
          v-model="searchQuery"
          @input="setSearchQuery"
          class="block pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-primary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-primary-600"
          placeholder="Search Patient"
        />
        <div
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <svg
            class="w-5 h-4 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fill-rule="evenodd"
              d="M12.9 14.32a8 8 0 111.42-1.42l4.62 4.62-1.42 1.42-4.62-4.62zM8 14a6 6 0 100-12 6 6 0 000 12z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <!-- Dropdown for Mobile -->
    <div class="md:hidden w-full relative sm:py-4 py-2 px-3">
      <button
        @click="toggleDropdown"
        class="inline-flex items-center justify-center w-full px-4 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
      >
        <i class="fa-solid fa-filter px-2 text-gray-400"></i>
        {{ selectedFilter }}
        <svg
          class="ml-2 -mr-1 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div
        v-if="dropdownOpen"
        class="absolute z-10 mt-2 w-full md:w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        style="width: calc(100% - 25px)"
      >
        <div>
          <a
            v-for="filter in filters"
            :key="filter"
            @click.prevent="setFilter(filter)"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
          >
            {{ filter }}
          </a>
        </div>
      </div>
    </div>

    <!-- Table -->
    <div class="overflow-x-auto">
      <table
        class="w-full text-base text-left text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-500 border-b border-t border-t-gray-200 border-b-gray-200 font-semibold uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr :class="getPatientRowClass">
            <th scope="col" class="sm:px-6 px-2 py-4 text-[10px] sm:text-xs">
              Patient Name
            </th>
            <th scope="col" class="sm:px-6 py-4 text-[10px] sm:text-xs">DOB</th>
            <th
              v-if="showAppointmentColumn"
              scope="col"
              class="sm:px-6 py-4 text-[10px] sm:text-xs"
            >
              Appointment Time
            </th>
            <th
              v-if="showProgressColumn"
              scope="col"
              class="sm:px-6 py-4 text-[10px] sm:text-xs"
            >
              <div class="flex items-center">
                Progress
                <a href="#" @click.prevent="toggleSortDirection">
                  <i class="px-2 fa-solid" :class="sortIconClass"></i>
                </a>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <!-- Loading Spinner -->
          <tr v-if="isLoading">
            <td colspan="5" class="px-6 py-4 text-center">
              <Spinner />
            </td>
          </tr>

          <!-- No Results Found -->
          <tr
            v-else-if="!isLoading && paginatedPatients.length === 0"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
          >
            <td colspan="5" class="px-6 py-4 text-center">
              <img
                src="~/assets/images/notfound.svg"
                alt="No Results Found"
                class="mx-auto block"
              />
              <p
                class="text-sm md:text-xl pb-10 font-semibold text-gray-900 dark:text-gray-400"
              >
                Looks like there aren't any <br />
                patients on this list.
              </p>
            </td>
          </tr>

          <!-- Patient Rows -->
          <tr
            v-else
            v-for="patient in paginatedPatients"
            :key="patient.id"
            :class="[
              getPatientRowClass,
              'bg-white border-b py-1 sm:py-0 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 cursor-pointer transition duration-150 ease-in-out',
            ]"
            @click="router.push(`/patient-details/${patient.id}`)"
          >
            <td class="sm:px-6 px-2 py-3 sm:py-6 h-4 flex items-center">
              <span
                :style="{ backgroundColor: patient.bgcolor }"
                class="hidden sm:inline-flex items-center justify-center w-8 h-8 font-medium text-xs sm:text-sm rounded-full text-gray-900"
              >
                {{ patient.name.charAt(0)
                }}{{ patient.name.split(" ")[1].charAt(0) }}
              </span>

              <span
                class="md:ml-3 font-medium font-inter leading-none text-xs sm:text-sm text-gray-900"
              >
                {{ patient.name }}
              </span>
            </td>
            <td
              class="sm:px-6 py-3 sm:py-6 font-normal font-inter leading-none text-xs sm:text-sm text-gray-400 h-4 flex items-center"
            >
              {{ patient.birthday }}
            </td>
            <td
              v-if="showAppointmentColumn"
              class="sm:px-6 py-3 sm:py-6 font-normal font-inter leading-none text-xs sm:text-sm text-gray-400 h-4 flex items-center"
            >
              {{ patient["appointment_time"] }}
            </td>
            <td v-if="showProgressColumn" class="sm:px-6 py-0 sm:py-3 h-4">
              <span
                class="bg-gray-100 text-gray-900 sm:px-2 sm:py-1 font-medium font-inter leading-none text-xs sm:text-sm h-4 items-center rounded-lg dark:bg-blue-200 dark:text-blue-800"
              >
                {{ patient.progress }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Pagination -->
    <div class="flex flex-wrap justify-between items-center bg-white px-6 py-4">
      <div
        class="flex items-center w-full md:w-auto justify-center md:justify-start"
      >
        <span class="hidden sm:flex text-sm text-gray-700 dark:text-gray-400"
          >Rows per page</span
        >
        <select
          v-model="itemsPerPage"
          @change="setItemsPerPage"
          class="ml-2 hidden sm:flex text-sm text-gray-500 dark:text-gray-400 bg-white border border-gray-300 rounded-lg focus:ring-primary-600 focus:border-primary-600"
        >
          <option>10</option>
          <option>20</option>
          <option>30</option>
        </select>
        <span
          class="flex sm:hidden font-inter text-sm mb-[2px] text-gray-400 dark:text-gray-400"
          >Showing</span
        >
        <span class="pl-2 text-sm font-inter text-gray-700 dark:text-gray-400">
          <span
            class="text-gray-900 font-inter font-semibold dark:text-gray-400"
            >{{ currentPageStart }}</span
          >-<span
            class="text-gray-900 font-inter font-semibold dark:text-gray-400"
            >{{ currentPageEnd }}</span
          >
          of
          <span
            class="text-gray-900 font-inter font-semibold dark:text-gray-400"
            >{{ totalPatients }}</span
          >
        </span>
      </div>
      <div
        class="hidden sm:flex items-center w-full justify-center md:w-auto md:justify-end mt-4 sm:mt-0"
      >
        <nav class="ml-4">
          <ul class="inline-flex items-center -space-x-px">
            <li>
              <a
                href="#"
                @click.prevent="setPage(currentPage - 1)"
                :class="{ 'cursor-not-allowed': currentPage === 1 }"
                class="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.7068 5.29303C12.8943 5.48056 12.9996 5.73487 12.9996 6.00003C12.9996 6.26519 12.8943 6.5195 12.7068 6.70703L9.41379 10L12.7068 13.293C12.8889 13.4816 12.9897 13.7342 12.9875 13.9964C12.9852 14.2586 12.88 14.5094 12.6946 14.6948C12.5092 14.8803 12.2584 14.9854 11.9962 14.9877C11.734 14.99 11.4814 14.8892 11.2928 14.707L7.29279 10.707C7.10532 10.5195 7 10.2652 7 10C7 9.73487 7.10532 9.48056 7.29279 9.29303L11.2928 5.29303C11.4803 5.10556 11.7346 5.00024 11.9998 5.00024C12.265 5.00024 12.5193 5.10556 12.7068 5.29303Z"
                    fill="#6B7280"
                  />
                </svg>
              </a>
            </li>
            <li v-for="page in totalPages" :key="page">
              <a
                href="#"
                @click.prevent="setPage(page)"
                :class="{
                  'font-bold bg-primary-100 text-primary-600':
                    currentPage === page,
                }"
                class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                {{ page }}
              </a>
            </li>
            <li>
              <a
                href="#"
                @click.prevent="setPage(currentPage + 1)"
                :class="{
                  'cursor-not-allowed': currentPage === totalPages,
                }"
                class="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.29279 14.707C7.10532 14.5195 7 14.2652 7 14C7 13.7349 7.10532 13.4806 7.29279 13.293L10.5858 10L7.29279 6.70704C7.11063 6.51844 7.00983 6.26584 7.01211 6.00364C7.01439 5.74144 7.11956 5.49063 7.30497 5.30522C7.49038 5.11981 7.74119 5.01465 8.00339 5.01237C8.26558 5.01009 8.51818 5.11088 8.70679 5.29304L12.7068 9.29304C12.8943 9.48057 12.9996 9.73488 12.9996 10C12.9996 10.2652 12.8943 10.5195 12.7068 10.707L8.70679 14.707C8.51926 14.8945 8.26495 14.9998 7.99979 14.9998C7.73462 14.9998 7.48031 14.8945 7.29279 14.707Z"
                    fill="#6B7280"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="flex sm:hidden place-content-center mb-4 items-center">
      <button
        @click.prevent="setPage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="px-4 py-2 w-[47.5%] text-sm font-inter leading-5 bg-primary-50 text-primary-600 rounded-l border border-gray-300 font-semibold disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-300 disabled:cursor-not-allowed"
      >
        Previous
      </button>

      <button
        @click.prevent="setPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="px-4 py-2 w-[47.5%] text-sm font-inter leading-5 bg-primary-200 text-primary-600 border rounded-r border-gray-300 font-semibold disabled:bg-gray-200 disabled:text-gray-500 disabled:border-gray-300 disabled:cursor-not-allowed"
      >
        Next
      </button>
    </div>
  </div>
</template>
