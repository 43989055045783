import { defineStore } from "pinia";
import { collection, getDocs } from "firebase/firestore";

interface Patient {
	id: string;
	name: string;
	birthday: string;
	appointment_time: string;
	progress: "Post-Treatment" | "New Patient" | "Treatment - IUI" | "Diagnostic";
	appointment_date: string;
	bgcolor: string;
}

interface PatientState {
	patients: Patient[];
	filteredPatients: Patient[];
	searchQuery: string;
	currentPage: number;
	itemsPerPage: number;
	selectedFilter: string;
	isLoading: boolean;
	sortDirection: "asc" | "desc";
}

export const usePatientStore = defineStore("patientStore", {
	state: (): PatientState => ({
		patients: [],
		filteredPatients: [],
		searchQuery: "",
		currentPage: 1,
		itemsPerPage: 10,
		selectedFilter: "All",
		isLoading: false,
		sortDirection: "asc",
	}),

	actions: {
		async loadPatients() {
			try {
				// const config = useRuntimeConfig();
				// const response = await fetch(`${config.public.apiUrl}/patients`);
				// const data = await response.json();

				const { $db } = useNuxtApp();
				const snapshot = await getDocs(collection($db, "Patients"));
				const data = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				this.patients = data as Patient[];

				this.filterByProgress(this.selectedFilter);
			} catch (error) {
				console.error("Failed to load patients:", error);
			}
		},

		setSearchQuery(query: string) {
			this.searchQuery = query;
			this.filterPatients();
		},

		filterPatients() {
			if (this.searchQuery) {
				const lowercasedQuery = this.searchQuery.toLowerCase();
				this.filteredPatients = this.patients.filter((patient) => {
					const nameMatch = patient.name
						.toLowerCase()
						.includes(lowercasedQuery);
					const dobMatch = patient.birthday.includes(lowercasedQuery);
					return nameMatch || dobMatch;
				});
			} else {
				this.filterByProgress(this.selectedFilter);
			}
			this.currentPage = 1;
		},

		setPage(page: number) {
			this.currentPage = page;
		},

		setItemsPerPage(items: number) {
			this.itemsPerPage = items;
		},

		setFilter(filter: string) {
			this.selectedFilter = filter;
			this.filterByProgress(filter);
		},

		filterByProgress(progress: string) {
			const today = new Date();
			const formattedToday = today
				.toLocaleDateString("en-US", {
					year: "numeric",
					month: "2-digit",
					day: "2-digit",
				})
				.replace(/\//g, "-");

			if (progress === "All") {
				this.filteredPatients = this.patients;
			} else if (progress === "Today") {
				this.filteredPatients = this.patients.filter(
					(patient) => patient.appointment_date === formattedToday,
				);
			} else {
				this.filteredPatients = this.patients.filter(
					(patient) => patient.progress === progress,
				);
			}

			this.sortPatientsByProgress(this.sortDirection);

			this.currentPage = 1;
		},

		sortPatientsByProgress(direction: "asc" | "desc") {
			this.sortDirection = direction;

			this.filteredPatients.sort((a, b) => {
				if (direction === "asc") {
					return a.progress.localeCompare(b.progress);
				} else {
					return b.progress.localeCompare(a.progress);
				}
			});
		},
	},

	getters: {
		paginatedPatients: (state): Patient[] => {
			const start = (state.currentPage - 1) * state.itemsPerPage;
			const end = start + state.itemsPerPage;
			return state.filteredPatients.slice(start, end);
		},

		totalPages: (state): number => {
			return Math.ceil(state.filteredPatients.length / state.itemsPerPage);
		},
	},
});
